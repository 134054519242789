import React from 'react'
import { useTranslation } from 'react-i18next'

const ErrorPage = ({ children }) => {
    const { t } = useTranslation()
    return (
        <div style={{ textAlign: 'center', margin: 'auto', width: '700px' }}>
            <a href="/">
                <h1>{children}</h1>
                <div>{t('Errors.ReturnToHomepage')}</div>
            </a>
        </div>
    )
}

export default ErrorPage
