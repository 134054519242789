/**
 * Main container
 */

import React, { useState, Fragment } from 'react'
import { compose } from 'redux'
import { withTranslation, useTranslation } from 'react-i18next'
import i18n from './config/initializers/i18n'
import history from './history'
import { Router, Route, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// components
import Frontpage from './pages/Frontpage'
import NatureSoundsPage from './pages/NatureSoundsPage'
import ErrorPage from './pages/ErrorPage/ErrorPage'
import ErrorMessage from './components/UI/ErrorMessage'

function App() {
    // eslint-disable-next-line no-unused-vars
    const [, setLocale] = useState(i18n.language)
    const { t } = useTranslation()

    const onLanguageChanged = value => setLocale(value)
    React.useEffect(() => {
        i18n.on('languageChanged', onLanguageChanged)
        return () => {
            i18n.off('languageChanged', onLanguageChanged)
        }
    }, [])
    return (
        <Fragment>
            <Helmet titleTemplate="Vill.dev | %s" defaultTitle="Vill.dev">
                <meta name="description" content="VillDev profilesite" />
                <meta
                    name="Keywords"
                    content="Vill.dev, villdev, VillDev, Vill, Dev, ViPu, Ville Saramaa, Ville, Saramaa, Projects, Coding, Finland, Helsinki, Software, Architect, Architecture, Developer, Development"
                />
            </Helmet>
            <Router history={history}>
                <Switch>
                    <Route
                        path="/"
                        exact
                        render={props => <Frontpage {...props} />}
                    />
                    <Route
                        path="/nature"
                        exact
                        render={props => <NatureSoundsPage {...props} />}
                    />
                    <Route
                        component={() => (
                            <ErrorPage>{t('Errors.PageNotFound')}</ErrorPage>
                        )}
                    />
                </Switch>
            </Router>
            {/*
					Render error message right away
					Will return null if there is no such error
				*/}
            <ErrorMessage />
        </Fragment>
    )
}

export default compose(withTranslation())(App)
