import React from 'react'
import { useTranslation } from 'react-i18next'
import Helmet from 'react-helmet'
import { Grid, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import RopesImg from '../../assets/images/ropes.jpg'
import BrainImg from '../../assets/images/brain.jpg'
import PocImg from '../../assets/images/poc.jpg'
import MicrolegoImg from '../../assets/images/microlego.jpg'
import ScrollTrigger from 'react-scroll-trigger'
import history from '../../history'

const useStyles = makeStyles(theme => ({
    bannerContainer: {
        width: '100%',
        position: 'relative',
        height: '600px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${RopesImg})`,
        backgroundPositionY: 'center',
        backgroundSize: 'cover',
        [theme.breakpoints.down('xl')]: {
            height: '600px'
        },
        [theme.breakpoints.down('lg')]: {
            height: '493px'
        },
        [theme.breakpoints.down('md')]: {
            height: '393px'
        }
    },
    headerContainer: {
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: '48rem',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '48rem'
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '32rem'
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '18rem'
        }
    },
    header: {
        textAlign: 'left !important',
        fontWeight: 600,
        color: `#ffffff`,
        display: 'inline',
        background: `#06f`,
        paddingBottom: '0px',
        marginBlockStart: theme.spacing(1) + 'px',
        marginBlockEnd: theme.spacing(1) + 'px',
        [theme.breakpoints.down('xl')]: {
            fontSize: '3.5rem !important'
        },
        [theme.breakpoints.down('lg')]: {
            paddingBottom: '0px',
            fontSize: '3rem !important'
        },
        [theme.breakpoints.down('md')]: {
            paddingBottom: '0px',
            fontSize: '2.5rem !important'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.5rem !important',
            paddingBottom: '20px'
        }
    },
    whiteBg: {
        backgroundColor: '#ffffff',
        margin: '0 auto'
    },
    grayBg: {
        backgroundColor: '#f5f3f2',
        margin: '0 auto'
    },
    root: {
        maxWidth: '980px',
        padding: '40px 20px',
        flexGrow: 1,
        margin: '0 auto !important',
        [theme.breakpoints.down('md')]: {
            margin: '30px 20px'
        },
        [theme.breakpoints.down('xs')]: {
            margin: '20px 15px'
        }
    },
    heading: {
        fontSize: '2.25rem',
        marginBottom: '1rem',
        fontWeight: 600,
        [theme.breakpoints.down('xl')]: {
            fontSize: '2.25rem !important'
        },
        [theme.breakpoints.down('lg')]: {
            paddingBottom: '0px',
            fontSize: '2rem !important'
        },
        [theme.breakpoints.down('md')]: {
            paddingBottom: '0px',
            fontSize: '1.5rem !important'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem !important',
            paddingBottom: '20px'
        }
    },
    text: {
        fontSize: '1rem',
        fontWeight: 400
    },
    link: {
        fontSize: '1rem',
        textDecoration: 'underline',
        fontWeight: 400,
        wordBreak: 'break-all'
    },
    quote: {
        fontSize: '1rem',
        fontWeight: 300,
        fontStyle: 'italic'
    },
    sideImg: {
        width: '18rem',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}))

export default function Coding(props) {
    const classes = useStyles()
    const { t } = useTranslation()
    const { id } = props
    return (
        <div>
            <a id="Coding" />
            <ScrollTrigger
                triggerOnLoad={false}
                throttleScroll={600}
                onEnter={() => history.push('#Coding')}
            >
                {id === '#Coding' && <Helmet title="💻"></Helmet>}
                <Grid className={classes.bannerContainer}>
                    <div className={classes.headerContainer}>
                        <Typography className={classes.header} variant="h1">
                            {t('FrontPage.coding.title')}
                        </Typography>
                    </div>
                </Grid>
                <div className={classes.whiteBg}>
                    <Grid container className={classes.root}>
                        <Grid item sm={8} xs={12}>
                            <Typography
                                variant="h2"
                                className={classes.heading}
                            >
                                {t('FrontPage.coding.intro.heading')}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.text}
                            >
                                {t('FrontPage.coding.intro.text')}
                            </Typography>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <img
                                src={PocImg}
                                className={classes.sideImg}
                                title="Lego it up"
                            />
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.grayBg}>
                    <Grid container className={classes.root}>
                        <Grid item sm={4} xs={12}>
                            <img
                                src={MicrolegoImg}
                                className={classes.sideImg}
                                title="Lego it up"
                            />
                        </Grid>
                        <Grid item sm={8} xs={12}>
                            <Typography
                                variant="h2"
                                className={classes.heading}
                            >
                                {t('FrontPage.coding.intro2.heading')}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.text}
                            >
                                {t('FrontPage.coding.intro2.text')}
                            </Typography>
                            <br />
                            <a
                                href={t('FrontPage.coding.intro2.link')}
                                alt={t('FrontPage.coding.intro2.link')}
                                className={classes.link}
                            >
                                {t('FrontPage.coding.intro2.link')}
                            </a>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.whiteBg}>
                    <Grid container className={classes.root}>
                        <Grid item sm={8} xs={12}>
                            <Typography
                                variant="h2"
                                className={classes.heading}
                            >
                                {t('FrontPage.coding.intro3.heading')}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.text}
                            >
                                {t('FrontPage.coding.intro3.quote1')}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.quote}
                            >
                                {t('FrontPage.coding.intro3.quote2')}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.text}
                            >
                                {t('FrontPage.coding.intro3.text')}
                            </Typography>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <img
                                src={BrainImg}
                                className={classes.sideImg}
                                title="Never stop learning"
                            />
                        </Grid>
                    </Grid>
                </div>
            </ScrollTrigger>
        </div>
    )
}
