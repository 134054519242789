import React, { useState, useEffect } from 'react'
import { Box, Modal } from '@material-ui/core'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    padding: 4
}

export default function BasicModal(props) {
    const [open, setOpen] = useState(false)
    const handleClose = () => {
        props.closeModal()
        setOpen(false)
    }

    useEffect(() => {
        if (props.forceOpen === true) {
            setOpen(true)
        }
    })

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>{props.children}</Box>
        </Modal>
    )
}
