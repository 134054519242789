import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Typography
} from '@material-ui/core'
import Logo from '../../assets/images/logo.svg'
import { LanguageChanger } from './LangueChanger/languageChanger'

import MenuIcon from '@material-ui/icons/Menu'

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: 'none',
        color: 'blue',
        fontSize: '20px'
    },
    icon: {
        color: 'white'
    },
    logo: {
        flexGrow: '1',
        cursor: 'pointer',
        width: '42px',
        height: '42px',
        background: '#3695ed',
        boxShadow: '0px 3px 1px black',
        display: 'inline-block',
        maskSize: 'cover',
        mask: `url(${Logo})`,
        '&:hover, &:focus': {
            transform: 'translateY(-.12rem) translateZ(0)'
        }
    }
}))

function DrawerComponent() {
    const classes = useStyles()
    const { t } = useTranslation()
    const [openDrawer, setOpenDrawer] = useState(false)
    return (
        <div>
            <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <List>
                    <ListItem onClick={() => setOpenDrawer(false)}>
                        <ListItemText>
                            <a href="#Intro" className={classes.link}>
                                <span className={classes.logo}></span>
                            </a>
                        </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem onClick={() => setOpenDrawer(false)}>
                        <ListItemText>
                            <a href="#Intro">
                                <Typography className={classes.link}>
                                    {t('Defaults.About')}
                                </Typography>
                            </a>
                        </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem onClick={() => setOpenDrawer(false)}>
                        <ListItemText>
                            <a href="#Coding">
                                <Typography className={classes.link}>
                                    {t('Defaults.Coding')}
                                </Typography>
                            </a>
                        </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem onClick={() => setOpenDrawer(false)}>
                        <ListItemText>
                            <a href="#Projects">
                                <Typography className={classes.link}>
                                    {t('Defaults.Projects')}
                                </Typography>
                            </a>
                        </ListItemText>
                    </ListItem>
                    <ListItem onClick={() => setOpenDrawer(false)}>
                        <ListItemText>
                            <a href="#Contact">
                                <Typography className={classes.link}>
                                    {t('Defaults.Contact')}
                                </Typography>
                            </a>
                        </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText>
                            <div className={classes.languageChangerContainer}>
                                <LanguageChanger />
                            </div>
                        </ListItemText>
                    </ListItem>
                </List>
            </Drawer>
            <IconButton
                onClick={() => setOpenDrawer(!openDrawer)}
                className={classes.icon}
            >
                <MenuIcon />
            </IconButton>
        </div>
    )
}
export default DrawerComponent
