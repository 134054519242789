import { useState, useEffect } from 'react'

export const useReactPath = () => {
    const [path, setPath] = useState(window.location.hash)
    const listenToPopstate = () => {
        const winPath = window.location.hash
        setPath(winPath)
    }
    useEffect(() => {
        window.addEventListener('popstate', listenToPopstate)
        return () => {
            window.removeEventListener('popstate', listenToPopstate)
        }
    }, [])
    return path
}
