import React, { useState } from 'react'
import ArrowUpward from '@material-ui/icons/ArrowUpward'

const ScrollButton = () => {
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop
        if (scrolled > 300) {
            setVisible(true)
        } else if (scrolled <= 300) {
            setVisible(false)
        }
    }

    const scrollToTop = () => {
        var pos = 0
        var time = 300
        var currentPos = window.pageYOffset
        var start = null
        ;(pos = +pos), (time = +time)
        window.requestAnimationFrame(function step(currentTime) {
            start = !start ? currentTime : start
            var progress = currentTime - start
            if (currentPos < pos) {
                window.scrollTo(
                    0,
                    ((pos - currentPos) * progress) / time + currentPos
                )
            } else {
                window.scrollTo(
                    0,
                    currentPos - ((currentPos - pos) * progress) / time
                )
            }
            if (progress < time) {
                window.requestAnimationFrame(step)
            } else {
                window.scrollTo(0, pos)
            }
        })
    }

    window.addEventListener('scroll', toggleVisible)

    return (
        <ArrowUpward
            onClick={scrollToTop}
            style={{
                display: visible ? 'inline' : 'none',
                position: 'fixed',
                right: '5',
                backgroundColor: 'white',
                borderRadius: '5px',
                border: '1px solid black',
                backgroundRepeat: 'no-repeat',
                overflow: 'hidden',
                outline: 'none',
                bottom: '30px',
                fontSize: '2rem',
                zIndex: '1',
                cursor: 'pointer',
                color: '#3695ed'
            }}
        />
    )
}

export default ScrollButton
