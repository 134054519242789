/**
 * Root client file
 */

// React stuffs
import React from 'react'
import ReactDOM from 'react-dom'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

// Styling
import './styles/index.scss'

// Components
import App from './App'

const theme = createTheme({
    palette: {
        primary: {
            main: '#06f'
        },
        secondary: {
            main: '#ed4d36'
        }
    },
    typography: {
        fontFamily: `"Nunito Sans", "Helvetica", "Arial", sans-serif`,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500
    }
})

const Index = () => {
    return (
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    )
}

ReactDOM.render(<Index />, document.getElementById('app'))
