import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    AppBar,
    Toolbar,
    CssBaseline,
    Typography,
    makeStyles,
    useTheme,
    useMediaQuery
} from '@material-ui/core'
import DrawerComponent from './Drawer'
import Logo from '../../assets/images/logo.svg'
import { LanguageChanger } from './LangueChanger/languageChanger'

const useStyles = makeStyles(theme => ({
    toolBar: {
        justifyContent: 'space-between',
        alignContent: 'center'
    },
    navlinks: {
        display: 'flex'
    },
    logoContainer: {
        display: 'flex'
    },
    logo: {
        flexGrow: '1',
        cursor: 'pointer',
        width: '42px',
        height: '42px',
        background: 'white',
        display: 'inline-block',
        maskSize: 'cover',
        mask: `url(${Logo})`,
        '&:hover': {
            transform: 'translateY(-.12rem) translateZ(0)'
        },
        [theme.breakpoints.down('sm')]: {
          width: '32px',
          height: '32px',
        }
    },
    logoText: {
        fontFamily: `"Flamenco", "Nunito Sans", "Helvetica", "Arial", sans-serif`,
        fontSize: '2rem',
        lineHeight: '1rem',
        fontWeight: '400',
        marginLeft: '1rem',
        color: 'white',
        '&:hover': {
            transform: 'translateY(-.12rem) translateZ(0)'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem'
        }
    },
    link: {
        textDecoration: 'none',
        color: 'white',
        fontSize: '20px',
        marginLeft: theme.spacing(15),
        '&:hover': {
            transform: 'translateY(-.12rem) translateZ(0)'
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '20px',
          marginLeft: theme.spacing(7),
        }
    },
    languageChangerContainer: {
        marginLeft: theme.spacing(10)
    }
}))

function Navbar() {
    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <AppBar position="fixed">
            <CssBaseline />
            <a style={{ display: 'none' }} id="intro"></a>
            <Toolbar className={classes.toolBar}>
                <div className={classes.logoContainer}>
                    <a href="#Intro">
                        <span className={classes.logo}></span>
                        <span className={classes.logoText}>vill.dev</span>
                    </a>
                </div>
                {isMobile ? (
                    <DrawerComponent />
                ) : (
                    <div className={classes.navlinks}>
                        <a href="#Intro">
                            <Typography className={classes.link}>
                                {t('Defaults.About')}
                            </Typography>
                        </a>
                        <a href="#Coding">
                            <Typography className={classes.link}>
                                {t('Defaults.Coding')}
                            </Typography>
                        </a>
                        <a href="#Projects">
                            <Typography className={classes.link}>
                                {t('Defaults.Projects')}
                            </Typography>
                        </a>
                        <a href="#Contact">
                            <Typography className={classes.link}>
                                {t('Defaults.Contact')}
                            </Typography>
                        </a>
                        <div className={classes.languageChangerContainer}>
                            <LanguageChanger whiteBg />
                        </div>
                    </div>
                )}
            </Toolbar>
        </AppBar>
    )
}
export default Navbar
