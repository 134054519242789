import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import Helmet from 'react-helmet'
import { Grid, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import EmailSVG from '../../assets/images/icons-email.svg'
import GithubSVG from '../../assets/images/icons-github.svg'
import TwitterSVG from '../../assets/images/icons-twitter.svg'
import LinkedinSVG from '../../assets/images/icons-linkedin.svg'

import ScrollTrigger from 'react-scroll-trigger'
import history from '../../history'

import ProfilePic from '../../assets/images/profile1.jpg'

const useStyles = makeStyles(theme => ({
    bg: {
        backgroundColor: '#f5f3f2',
        margin: '0 auto'
    },
    root: {
        maxWidth: '980px',
        padding: '40px 20px',
        flexGrow: 1,
        margin: '0 auto !important',
        [theme.breakpoints.down('md')]: {
            margin: '30px 20px'
        },
        [theme.breakpoints.down('xs')]: {
            margin: '20px 15px'
        }
    },
    heading: {
        fontSize: '2.25rem',
        fontWeight: 600,
        [theme.breakpoints.down('xl')]: {
            fontSize: '2.25rem !important'
        },
        [theme.breakpoints.down('lg')]: {
            paddingBottom: '0px',
            fontSize: '2rem !important'
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '0px',
            fontSize: '1.4rem !important'
        }
    },
    subheader: {
        fontSize: '1.25rem',
        marginBottom: '1rem',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '0px',
            fontSize: '1rem !important'
        }
    },
    textlink: {
        fontWeight: 600,
        textDecoration: 'underline',
    },
    text: {
        fontSize: '1rem',
        fontWeight: 400
    },
    profilepic: {
        width: '10rem',
        borderRadius: '5px'
    },
    profilePicContainer: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            marginTop: '25px'
        }
    },
    socialsContainer: {
        margin: '0 auto',
        paddingTop: '15px',
        textAlign: 'center'
    },
    iconEmail: {
        margin: '0 7px',
        width: '25px',
        height: '25px',
        display: 'inline-block',
        background: 'black',
        maskSize: 'cover',
        mask: `url(${EmailSVG})`,
        '&:hover, &:focus': {
            transform: 'translateY(-.12rem) translateZ(0)'
        }
    },
    iconGithub: {
        margin: '0 7px',
        width: '25px',
        height: '25px',
        display: 'inline-block',
        background: 'black',
        maskSize: 'cover',
        mask: `url(${GithubSVG})`,
        '&:hover, &:focus': {
            transform: 'translateY(-.12rem) translateZ(0)'
        }
    },
    iconTwitter: {
        margin: '0 7px',
        width: '25px',
        height: '25px',
        display: 'inline-block',
        background: 'black',
        maskSize: 'cover',
        mask: `url(${TwitterSVG})`,
        '&:hover, &:focus': {
            transform: 'translateY(-.12rem) translateZ(0)'
        }
    },
    iconLinkedin: {
        margin: '0 7px',
        width: '25px',
        height: '25px',
        display: 'inline-block',
        background: 'black',
        maskSize: 'cover',
        mask: `url(${LinkedinSVG})`,
        '&:hover, &:focus': {
            transform: 'translateY(-.12rem) translateZ(0)'
        }
    },
    buyCoffee: {
        margin: '0 auto',
        paddingTop: '15px',
        textAlign: 'center'
    },
    buyCoffeeButton: {
        margin: '1rem',
        width: '10rem',
        '-webkit-transform': 'rotate(-5deg)' /* Safari and Chrome */,
        '-moz-transform': 'rotate(-5deg)' /* Firefox */,
        '-ms-transform': 'rotate(-5deg)' /* IE 9 */,
        '-o-transform': 'rotate(-5deg)' /* Opera */,
        transform: 'rotate(-5deg)'
    }
}))

export default function Intro(props) {
    const classes = useStyles()
    const { t } = useTranslation()
    const { id } = props

    return (
        <div className={classes.bg}>
            <ScrollTrigger
                triggerOnLoad={false}
                throttleScroll={600}
                onEnter={() => history.push('#Intro')}
            >
                {id === '#Intro' && <Helmet title="🧘🏻‍♂️"></Helmet>}
                <Grid container className={classes.root}>
                    <Grid item sm={9} xs={12}>
                        <Typography variant="h2" className={classes.heading}>
                            {t('FrontPage.intro.heading')}
                        </Typography>
                        <Typography variant="h3" className={classes.subheader}>
                            {t('FrontPage.intro.heading2')}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            className={classes.text}
                        >
                            <Trans
                                i18nKey="FrontPage.intro.text"
                                components={[<a key="nitorLink" target="_blank" rel="noopener noreferrer" className={classes.textlink} href="https://nitor.com/" />]}
                            />
                        </Typography>
                        <br />
                        <Typography
                            variant="subtitle1"
                            className={classes.text}
                        >
                            {t('FrontPage.About.text')}
                        </Typography>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <div className={classes.profilePicContainer}>
                            <img
                                src={ProfilePic}
                                className={classes.profilepic}
                                alt="Profile pic"
                            />
                        </div>
                        <div className={classes.socialsContainer}>
                            <a
                                href="mailto:ville.saramaa@gmail.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span className={classes.iconEmail}></span>
                            </a>

                            <a
                                href="https://github.com/villdevelop"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span className={classes.iconGithub}></span>
                            </a>

                            <a
                                href="https://twitter.com/villdevelop"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span className={classes.iconTwitter}></span>
                            </a>

                            <a
                                href="https://www.linkedin.com/in/villesaramaa"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span className={classes.iconLinkedin}></span>
                            </a>
                        </div>
                        <div className={classes.buyCoffee}>
                            <a
                                href="https://www.buymeacoffee.com/villdev"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className={classes.buyCoffeeButton}
                                    src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
                                    alt="Buy Me A Coffee"
                                />
                            </a>
                        </div>
                    </Grid>
                </Grid>
            </ScrollTrigger>
        </div>
    )
}
