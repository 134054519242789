import React from 'react'
import { Grid } from '@material-ui/core'
import Helmet from 'react-helmet'

import { makeStyles } from '@material-ui/core/styles'
import LogoWhite from '../../assets/images/logo-white.svg'
import EmailSVG from '../../assets/images/icons-email.svg'
import GithubSVG from '../../assets/images/icons-github.svg'
import TwitterSVG from '../../assets/images/icons-twitter.svg'
import LinkedinSVG from '../../assets/images/icons-linkedin.svg'
import ScrollTrigger from 'react-scroll-trigger'
import history from '../../history'

const useStyles = makeStyles(() => ({
    footerBg: {
        backgroundColor: '#000000',
        paddingBottom: '10rem'
    },
    root: {
        maxWidth: '1300px',
        margin: '0 auto',
        flexGrow: 1,
        width: '100%'
    },
    footerLogo: {
        width: '8rem'
    },
    logoItemContainer: {
        margin: '10rem auto 20px auto',
        textAlign: 'center'
    },
    socialsContainer: {
        margin: '0px auto 50px auto',
        textAlign: 'center'
    },
    iconEmail: {
        margin: '0 5px',
        width: '25px',
        height: '25px',
        display: 'inline-block',
        background: 'white',
        maskSize: 'cover',
        mask: `url(${EmailSVG})`,
        '&:hover, &:focus': {
            transform: 'translateY(-.12rem) translateZ(0)'
        }
    },
    iconGithub: {
        margin: '0 5px',
        width: '25px',
        height: '25px',
        display: 'inline-block',
        background: 'white',
        maskSize: 'cover',
        mask: `url(${GithubSVG})`,
        '&:hover, &:focus': {
            transform: 'translateY(-.12rem) translateZ(0)'
        }
    },
    iconTwitter: {
        margin: '0 5px',
        width: '25px',
        height: '25px',
        display: 'inline-block',
        background: 'white',
        maskSize: 'cover',
        mask: `url(${TwitterSVG})`,
        '&:hover, &:focus': {
            transform: 'translateY(-.12rem) translateZ(0)'
        }
    },
    iconLinkedin: {
        margin: '0 5px',
        width: '25px',
        height: '25px',
        display: 'inline-block',
        background: 'white',
        maskSize: 'cover',
        mask: `url(${LinkedinSVG})`,
        '&:hover, &:focus': {
            transform: 'translateY(-.12rem) translateZ(0)'
        }
    }
}))

export default function Footer(props) {
    const classes = useStyles()
    const { id } = props

    return (
        <div className={classes.footerBg}>
            <a id="Contact" />
            <ScrollTrigger
                triggerOnLoad={false}
                throttleScroll={600}
                onEnter={() => history.push('#Contact')}
            >
                {id === '#Contact' && <Helmet title="📬"></Helmet>}
                <Grid container className={classes.root}>
                    <Grid item className={classes.logoItemContainer} xs={12}>
                        <a href="#Intro">
                            <img
                                src={LogoWhite}
                                className={classes.footerLogo}
                                alt="Footer logo"
                            />
                        </a>
                    </Grid>
                    <Grid item className={classes.socialsContainer} xs={12}>
                        <a
                            href="mailto:ville.saramaa@gmail.com"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <span className={classes.iconEmail}></span>
                        </a>

                        <a
                            href="https://github.com/villdevelop"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <span className={classes.iconGithub}></span>
                        </a>

                        <a
                            href="https://twitter.com/villdevelop"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <span className={classes.iconTwitter}></span>
                        </a>

                        <a
                            href="https://www.linkedin.com/in/villesaramaa"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <span className={classes.iconLinkedin}></span>
                        </a>
                    </Grid>
                </Grid>
            </ScrollTrigger>
        </div>
    )
}
