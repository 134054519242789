import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import Banner from './banner'
import Footer from './footer'
import Intro from './intro'
import Coding from './coding'
import Projects from './projects'
import ScrollButton from '../../components/UI/scrollButton'
import { makeStyles } from '@material-ui/core/styles'
import Navbar from '../../components/UI/Navbar'
import { useReactPath } from '../../hooks/useReactPath'

const useStyles = makeStyles(() => ({
    wrapper: {
        width: '100%'
    }
}))

export default function Frontpage() {
    const classes = useStyles()
    const [id, setId] = useState(
        window.location?.hash ? window.location?.hash : ''
    )
    const path = useReactPath()
    useEffect(() => {
        setId(path)
    }, [path])

    return (
        <Grid className={classes.wrapper} data-cy="front-page">
            <Navbar />
            <Banner id={'#Intro'} />
            <Intro id={id} />
            <Coding id={id} />
            <Projects id={id} />
            <ScrollButton />
            <Footer id={id} />
        </Grid>
    )
}
