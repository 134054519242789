import React, { useState } from 'react'
import { supportedLanguages } from '../../../config/i18n'
import { useTranslation } from 'react-i18next'
import { makeStyles, Select, MenuItem } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    languageChangeContainerWhiteBg: {
        color: 'black',
        background: 'white',
        borderRadius: '5px',
        justifyContent: 'space-evenly',
        maxWidth: '500px',
        padding: '0.5em !important',
        '&:hover, &:focus': {
            background: 'white',
            borderRadius: '5px'
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            margin: '0 auto'
        }
    },
    languageChangeContainerLight: {
        color: 'black',
        justifyContent: 'space-evenly',
        maxWidth: '500px',
        padding: '0.5em',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            margin: '0 auto'
        }
    },
    languageChangeContainerDark: {
        color: 'white',
        justifyContent: 'space-evenly',
        maxWidth: '500px',
        padding: '0.5em',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            margin: '0 auto'
        }
    }
}))

export const LanguageChanger = props => {
    const { dark, whiteBg } = props
    const classes = useStyles()
    const { i18n } = useTranslation()
    const [lang, setLang] = useState(i18n.language ? i18n.language : 'fi')

    const changeLanguage = event => {
        const language = event.target.value
        if (supportedLanguages.find(obj => obj.locale === i18n.language)) {
            localStorage.setItem('locale', language)
            i18n.changeLanguage(language)
            setLang(language)
        }
    }

    function countryToFlag(isoCode) {
        return typeof String.fromCodePoint !== 'undefined'
            ? isoCode
                  .toUpperCase()
                  .replace(/./g, char =>
                      String.fromCodePoint(char.charCodeAt(0) + 127397)
                  )
            : isoCode
    }

    if (whiteBg) {
        return (
            <Select
                disableUnderline
                labelId="change-language-label"
                id="change-language-select"
                value={lang}
                IconComponent={() => null}
                onChange={changeLanguage}
                classes={{
                    root: classes.languageChangeContainerWhiteBg
                }}
            >
                {supportedLanguages.map(language => (
                    <MenuItem
                        key={language.code}
                        aria-label={'change-language-to' + language.locale}
                        value={language.locale}
                    >
                        {countryToFlag(language.code)} {language.name}
                    </MenuItem>
                ))}
            </Select>
        )
    }

    return (
        <Select
            disableUnderline
            labelId="change-language-label"
            id="change-language-select"
            value={lang}
            onChange={changeLanguage}
            classes={{
                root: dark
                    ? classes.languageChangeContainerDark
                    : classes.languageChangeContainerLight
            }}
        >
            {supportedLanguages.map(language => (
                <MenuItem
                    key={language.code}
                    aria-label={'change-language-to' + language.locale}
                    value={language.locale}
                >
                    {countryToFlag(language.code)} {language.name}
                </MenuItem>
            ))}
        </Select>
    )
}
