import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Helmet from 'react-helmet'
import { Button, Grid, Typography } from '@material-ui/core'
import PlayArrow from '@material-ui/icons/PlayArrow'
import Carousel from 'react-material-ui-carousel'

import { makeStyles } from '@material-ui/core/styles'

import Modal from '../../components/UI/Modal'

import Arduino1Img from '../../assets/images/arduino1.jpg'
import Arduino2Img from '../../assets/images/arduino2.jpg'
import Arduino3Img from '../../assets/images/arduino3.jpg'
import BannerImg from '../../assets/images/projects-banner.jpg'
import TerrainsenseImg from '../../assets/images/terrainsense.jpg'
import Terrainsense2Img from '../../assets/images/terrainsense2.jpg'
import DatawarehouseImg from '../../assets/images/bigdata.jpg'
import CodenomiconImg from '../../assets/images/analytics.jpg'
import profilesiteImg from '../../assets/images/profilesite-project.jpg'
import NatureSoundsImg from '../../assets/images/trees.jpg'
import TerrainsenseVideo from '../../assets/videos/terrainsense_video.mp4'
import ScrollTrigger from 'react-scroll-trigger'
import history from '../../history'

const useStyles = makeStyles(theme => ({
    bannerContainer: {
        width: '100%',
        position: 'relative',
        height: '600px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${BannerImg})`,
        backgroundPositionY: 'center',
        backgroundSize: 'cover',
        [theme.breakpoints.down('xl')]: {
            height: '600px'
        },
        [theme.breakpoints.down('lg')]: {
            height: '493px'
        },
        [theme.breakpoints.down('md')]: {
            height: '393px'
        }
    },
    headerContainer: {
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: '48rem',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '48rem'
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '32rem'
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '18rem'
        }
    },
    header: {
        textAlign: 'left !important',
        fontWeight: 600,
        color: `#ffffff`,
        display: 'inline',
        background: `#06f`,
        paddingBottom: '0px',
        marginBlockStart: theme.spacing(1) + 'px',
        marginBlockEnd: theme.spacing(1) + 'px',
        [theme.breakpoints.down('xl')]: {
            fontSize: '3.5rem !important'
        },
        [theme.breakpoints.down('lg')]: {
            paddingBottom: '0px',
            fontSize: '3rem !important'
        },
        [theme.breakpoints.down('md')]: {
            paddingBottom: '0px',
            fontSize: '2.5rem !important'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.5rem !important',
            paddingBottom: '20px'
        }
    },
    whiteBg: {
        backgroundColor: '#ffffff',
        margin: '0 auto'
    },
    grayBg: {
        backgroundColor: '#f5f3f2',
        margin: '0 auto'
    },
    root: {
        maxWidth: '980px',
        padding: '40px 20px 0 20px',
        flexGrow: 1,
        margin: '0 auto !important',
        [theme.breakpoints.down('md')]: {
            margin: '30px 20px 0 20px'
        },
        [theme.breakpoints.down('xs')]: {
            margin: '20px 15px 0 15px'
        }
    },
    rootBt: {
        maxWidth: '980px',
        padding: '40px 20px',
        flexGrow: 1,
        margin: '0 auto !important',
        borderBottom: '1px solid black',
        [theme.breakpoints.down('md')]: {
            margin: '30px 20px'
        },
        [theme.breakpoints.down('xs')]: {
            margin: '20px 15px'
        }
    },
    subheader: {
        fontSize: '2rem',
        whiteSpace: 'pre-wrap',
        fontWeight: 600,
        [theme.breakpoints.down('xl')]: {
            fontSize: '2rem !important'
        },
        [theme.breakpoints.down('lg')]: {
            paddingBottom: '0px',
            fontSize: '1.8rem !important'
        },
        [theme.breakpoints.down('md')]: {
            paddingBottom: '0px',
            fontSize: '1.5rem !important'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.3rem !important',
            paddingBottom: '20px'
        }
    },
    heading: {
        fontSize: '1.8rem',
        marginBottom: '1rem',
        whiteSpace: 'pre-wrap',
        fontWeight: 600,
        [theme.breakpoints.down('xl')]: {
            fontSize: '2.25rem !important'
        },
        [theme.breakpoints.down('lg')]: {
            paddingBottom: '0px',
            fontSize: '2rem !important'
        },
        [theme.breakpoints.down('md')]: {
            paddingBottom: '0px',
            fontSize: '1.5rem !important'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem !important',
            paddingBottom: '20px'
        }
    },
    endingWords: {
        fontSize: '1.8rem',
        marginBottom: '1rem',
        whiteSpace: 'pre-wrap',
        fontWeight: 600,
        [theme.breakpoints.down('xl')]: {
            fontSize: '1.8rem !important'
        },
        [theme.breakpoints.down('lg')]: {
            paddingBottom: '0px',
            fontSize: '1.5rem !important'
        },
        [theme.breakpoints.down('md')]: {
            paddingBottom: '0px',
            fontSize: '1.2rem !important'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem !important',
            paddingBottom: '20px'
        }
    },
    text: {
        fontSize: '1rem',
        fontWeight: 400,
        whiteSpace: 'pre-line',
        [theme.breakpoints.down('sm')]: {
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            wordBreak: 'break-word'
        }
    },
    link: {
        fontSize: '1rem',
        textDecoration: 'underline',
        fontWeight: 400,
        wordBreak: 'break-all'
    },
    keywords: {
        fontSize: '1rem',
        marginTop: '15px',
        fontWeight: 900
    },
    projectImg: {
        paddingLeft: '15px',
        width: '100%',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            padding: '1rem 0 0 0',
            width: '100%'
        }
    },
    natureSoundsImg: {
        paddingLeft: '15px',
        maxWidth: '25rem',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '0'
        }
    },
    natureSoundsImgContainer: {
        float: 'right',
        [theme.breakpoints.down('sm')]: {
            float: 'inherit',
            width: '100%',
            margin: '0 auto',
            paddingBottom: '15px'
        }
    },
    carouselImg: {
        paddingLeft: '15px',
        width: '15rem',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '20px 0 0 0',
            width: '50%',
        }
    },
    modalImg: {
        maxWidth: '30rem',
        [theme.breakpoints.down('sm')]: {
            width: '20rem'
        }
    },
    carouselContainer: {
        paddingLeft: '15px',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            padding: '0'
        }
    },
    arduinoImageCarousel: {
      margin: '0 auto',
    }
}))

export default function Projects(props) {
    const classes = useStyles()
    const [imageModalOpen, setImageModalOpen] = useState(null)
    const { t } = useTranslation()
    const { id } = props

    return (
        <div>
            <a id="Projects" />
            <ScrollTrigger
                triggerOnLoad={false}
                throttleScroll={600}
                onEnter={() => history.push('#Projects')}
            >
                {id === '#Projects' && <Helmet title="👷🏗️"></Helmet>}
                <Grid className={classes.bannerContainer}>
                    <div className={classes.headerContainer}>
                        <Typography variant="h1" className={classes.header}>
                            {t('FrontPage.projects.title')}
                        </Typography>
                    </div>
                </Grid>
                <Modal
                    closeModal={() => setImageModalOpen(null)}
                    forceOpen={imageModalOpen != null ? true : false}
                >
                    <Carousel
                        animation="fade"
                        autoPlay={true}
                        interval={5000}
                        stopAutoPlayOnHover={true}
                        navButtonsAlwaysVisible
                        indicators={false}
                    >
                        <img
                            key={1}
                            src={Arduino1Img}
                            className={classes.modalImg}
                            title={t(
                                'FrontPage.projects.arduinomoisture.title'
                            )}
                        />
                        <img
                            key={2}
                            src={Arduino2Img}
                            className={classes.modalImg}
                            title={t(
                                'FrontPage.projects.arduinomoisture.title'
                            )}
                        />
                        <img
                            key={3}
                            src={Arduino3Img}
                            className={classes.modalImg}
                            title={t(
                                'FrontPage.projects.arduinomoisture.title'
                            )}
                        />
                    </Carousel>
                </Modal>
                <div className={classes.whiteBg}>
                    <Grid container className={classes.root}>
                        <Typography variant="h2" className={classes.subheader}>
                            {t('FrontPage.projects.subtitle')}
                        </Typography>
                    </Grid>

                    <Grid container className={classes.rootBt}>
                        <Grid item xl={12}>
                            <div className={classes.natureSoundsImgContainer}>
                                <a
                                    href={t(
                                        'FrontPage.projects.naturesounds.link'
                                    )}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={NatureSoundsImg}
                                        className={classes.natureSoundsImg}
                                        title="Naturesounds"
                                    />
                                </a>
                            </div>
                            <Typography
                                variant="h1"
                                className={classes.heading}
                            >
                                {t('FrontPage.projects.naturesounds.title')}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.text}
                            >
                                {t('FrontPage.projects.naturesounds.text')}
                            </Typography>
                            <p>
                                <a
                                    className={classes.link}
                                    href={t(
                                        'FrontPage.projects.naturesounds.link'
                                    )}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t(
                                        'FrontPage.projects.naturesounds.linkText'
                                    )}
                                </a>
                            </p>
                            <Typography
                                variant="body1"
                                className={classes.keywords}
                            >
                                {t('FrontPage.projects.naturesounds.keywords')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.rootBt}>
                        <Grid item md={9} sm={8} xs={12}>
                            <Typography
                                variant="h1"
                                className={classes.heading}
                            >
                                {t('FrontPage.projects.arduinomoisture.title')}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.text}
                            >
                                {t('FrontPage.projects.arduinomoisture.text')}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.keywords}
                            >
                                {t(
                                    'FrontPage.projects.arduinomoisture.keywords'
                                )}
                            </Typography>
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                            <div className={classes.carouselContainer}>
                                <Carousel
                                    animation="fade"
                                    autoPlay={true}
                                    interval={5000}
                                    height={300}
                                    changeOnFirstRender={true}
                                    stopAutoPlayOnHover={true}
                                    className={classes.arduinoImageCarousel}
                                >
                                    <img
                                        key={1}
                                        src={Arduino1Img}
                                        className={classes.carouselImg}
                                        onClick={() => setImageModalOpen(true)}
                                        title={t(
                                            'FrontPage.projects.arduinomoisture.title'
                                        )}
                                    />
                                    <img
                                        key={2}
                                        src={Arduino2Img}
                                        className={classes.carouselImg}
                                        onClick={() => setImageModalOpen(true)}
                                        title={t(
                                            'FrontPage.projects.arduinomoisture.title'
                                        )}
                                    />
                                    <img
                                        key={3}
                                        src={Arduino3Img}
                                        className={classes.carouselImg}
                                        onClick={() => setImageModalOpen(true)}
                                        title={t(
                                            'FrontPage.projects.arduinomoisture.title'
                                        )}
                                    />
                                </Carousel>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.rootBt}>
                        <Grid item sm={6} xs={12}>
                            <Typography
                                variant="h1"
                                className={classes.heading}
                            >
                                {t(
                                    'FrontPage.projects.terrainsenseproject.title'
                                )}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.text}
                            >
                                {t(
                                    'FrontPage.projects.terrainsenseproject.text'
                                )}
                            </Typography>
                            <br />
                            <Button href={TerrainsenseVideo} target="_blank" color="primary" variant="contained" endIcon={<PlayArrow />}>
                              {t(
                                    'FrontPage.projects.terrainsenseproject.videoButton'
                              )}
                            </Button>
                            <Typography
                                variant="body1"
                                className={classes.keywords}
                            >
                                {t(
                                    'FrontPage.projects.terrainsenseproject.keywords'
                                )}
                            </Typography>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <img
                                src={TerrainsenseImg}
                                className={classes.projectImg}
                                title={t(
                                  'FrontPage.projects.terrainsenseproject.title'
                                )}
                            />
                            <img
                                src={Terrainsense2Img}
                                className={classes.projectImg}
                                title={t(
                                  'FrontPage.projects.terrainsenseproject.title'
                                ) + ' app'}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.rootBt}>
                        <Grid item sm={7} xs={12}>
                            <Typography
                                variant="h1"
                                className={classes.heading}
                            >
                                {t('FrontPage.projects.datawarehouse.title')}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.text}
                            >
                                {t('FrontPage.projects.datawarehouse.text')}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.keywords}
                            >
                                {t('FrontPage.projects.datawarehouse.keywords')}
                            </Typography>
                        </Grid>
                        <Grid item sm={5} xs={12}>
                            <img
                                src={DatawarehouseImg}
                                className={classes.projectImg}
                                title={t(
                                    'FrontPage.projects.datawarehouse.alt'
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.rootBt}>
                        <Grid item sm={7} xs={12}>
                            <Typography
                                variant="h1"
                                className={classes.heading}
                            >
                                {t('FrontPage.projects.profilesite.title')}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.text}
                            >
                                {t('FrontPage.projects.profilesite.text')}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.keywords}
                            >
                                {t('FrontPage.projects.profilesite.keywords')}
                            </Typography>
                        </Grid>
                        <Grid item sm={5} xs={12}>
                            <img
                                src={profilesiteImg}
                                className={classes.projectImg}
                                title={t('FrontPage.projects.profilesite.alt')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.rootBt}>
                        <Grid item sm={7} xs={12}>
                            <Typography
                                variant="h1"
                                className={classes.heading}
                            >
                                {t('FrontPage.projects.codenomicon.title')}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.text}
                            >
                                {t('FrontPage.projects.codenomicon.text')}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.keywords}
                            >
                                {t('FrontPage.projects.codenomicon.keywords')}
                            </Typography>
                        </Grid>
                        <Grid item sm={5} xs={12}>
                            <img
                                src={CodenomiconImg}
                                className={classes.projectImg}
                                title={t('FrontPage.projects.codenomicon.alt')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.root}>
                        <Typography
                            variant="h2"
                            className={classes.endingWords}
                        >
                            {t('FrontPage.projects.endingwords')}
                        </Typography>
                    </Grid>
                </div>
            </ScrollTrigger>
        </div>
    )
}
