import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import BannerImg from '../../assets/images/banner.jpg'

const useStyles = makeStyles(theme => ({
    bannerContainer: {
        width: '100%',
        position: 'relative',
        height: '600px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${BannerImg})`,
        backgroundSize: 'cover',
        backgroundPositionY: 'center',
        [theme.breakpoints.down('xl')]: {
            height: '600px'
        },
        [theme.breakpoints.down('lg')]: {
            height: '493px'
        },
        [theme.breakpoints.down('md')]: {
            height: '393px'
        }
    },
    headerContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '980px',
        [theme.breakpoints.down('md')]: {
            margin: '30px 20px'
        },
        [theme.breakpoints.down('xs')]: {
            margin: '20px 15px'
        }
    },
    headerSubContainer: {
        marginLeft: '0',
        marginRight: 'auto',
        maxWidth: '40rem',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '30rem'
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '20rem',
            marginRight: '0px'
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '18rem'
        }
    },
    header: {
        textAlign: 'left !important',
        fontWeight: 600,
        color: `#ffffff`,
        display: 'inline',
        background: `#06f`,
        paddingBottom: '0px',
        [theme.breakpoints.down('xl')]: {
            fontSize: '2.5rem !important'
        },
        [theme.breakpoints.down('lg')]: {
            paddingBottom: '0px',
            fontSize: '2.2rem !important'
        },
        [theme.breakpoints.down('md')]: {
            paddingBottom: '0px',
            fontSize: '1.5rem !important'
        }
    }
}))

export default function Banner() {
    const classes = useStyles()
    const { t } = useTranslation()
    return (
        <Grid className={classes.bannerContainer}>
            <a id="Intro" style={{ position: 'relative', top: '-10em' }} />
            <div className={classes.headerContainer}>
                <div className={classes.headerSubContainer}>
                    <Typography className={classes.header} variant="h1">
                        {t('FrontPage.title')}
                    </Typography>
                </div>
            </div>
        </Grid>
    )
}
