import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import fi from '../../localization/fi'
import en from '../../localization/en'

function getLocalStorage(key) {
    let value = null
    const localStorageVal = localStorage.getItem(key)
    if (localStorageVal) {
        value =
            key === 'filter_params'
                ? JSON.parse(localStorageVal)
                : localStorageVal
    }
    return value
}

i18n.use(initReactI18next).init({
    debug: false,
    initImmediate: false,
    preload: ['en', 'fi'],
    fallbackLng: 'en',
    lng: getLocalStorage('locale') != 'undefined' ? getLocalStorage('locale') : 'en',
    resources: {
      en: { translation: en },
      fi: { translation: fi }
    },
    interpolation: {
        escapeValue: false
    },
    useSuspense: false
})

export default i18n
